<template>
  <div>
    <ul class="steps is-narrow is-centered has-content-centered">
      <li
        class="steps-segment"
        :class="{ 'is-active': currentStep === steps.CreateAssign.id}">
        <a
          href="#"
          class="has-text-dark">
          <span class="steps-marker">
            <span class="icon">
              <i class="mdi mdi-lead-pencil" />
            </span>
          </span>
          <div class="steps-content">
            <p class="heading">Create{{ ecomType !== 'audanet' ? `/Assign` : '' }} Quote</p>
          </div>
        </a>
      </li>
      <li
        class="steps-segment"
        :class="{ 'is-active': currentStep === steps.SelectInsurer.id}">
        <a
          hef="#"
          class="has-text-dark">
          <span class="steps-marker">
            <span class="icon">
              <i class="mdi mdi-verified" />
            </span>
          </span>
          <div class="steps-content">
            <p class="heading">Select Insurer</p>
          </div>
        </a>
      </li>
      <li
        class="steps-segment has-gaps"
        :class="{ 'is-active': currentStep === steps.UpdateCustomer.id}">
        <span class="steps-marker">
          <span class="icon">
            <i class="mdi mdi-account" />
          </span>
        </span>
        <div class="steps-content">
          <p class="heading">Update Customer</p>
        </div>
      </li>
      <li
        v-if="steps.OverwriteVehicle.enabled"
        class="steps-segment has-gaps"
        :class="{ 'is-active': currentStep === steps.OverwriteVehicle.id}">
        <span class="steps-marker">
          <span class="icon">
            <i class="mdi mdi-account" />
          </span>
        </span>
        <div class="steps-content">
          <p class="heading">Overwrite Vehicle</p>
        </div>
      </li>
    </ul>
    <div
      v-show="currentStep === steps.CreateAssign.id"
      class="box">
      <load-quote-create-step
        v-model="value"
        :ecom-type="ecomType" />
    </div>
    <div
      v-show="currentStep === steps.SelectInsurer.id"
      class="box">
      <load-quote-insurer-step
        v-model="value"
        :insurer-name="value.insurerName"
        :ecom-type="ecomType" />
    </div>
    <div
      v-show="currentStep === steps.UpdateCustomer.id"
      class="box">
      <load-quote-customer-step v-model="value" />
    </div>
    <div
      v-show="currentStep === steps.OverwriteVehicle.id && steps.OverwriteVehicle.enabled"
      class="box">
      <load-quote-vehicle-step v-model="value" />
    </div>
  </div>
</template>

<script>
import LoadQuoteCreateStep from './LoadQuoteCreateStep'
import LoadQuoteInsurerStep from './LoadQuoteInsurerStep'
import LoadQuoteCustomerStep from './LoadQuoteCustomerStep'
import LoadQuoteVehicleStep from './LoadQuoteVehicleStep'

export default {
  name: 'LoadQuoteSteps',
  components: {
    LoadQuoteCreateStep,
    LoadQuoteInsurerStep,
    LoadQuoteCustomerStep,
    LoadQuoteVehicleStep
  },
  props: {
    value: null,
    currentStep: {
      type: Number
    },
    steps: null,
    ecomType: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    // steps() {
    //   return Steps
    // }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {}
}
</script>
