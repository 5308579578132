import FqApi from '@/services/fq-api'

export default {
  async getInsurerSummaries(ecomType, name) {
    const url = ecomType !== 'audanet' ? `/insurers/loadquote/insurerlist/${ecomType}` : `/insurers/loadtask/insurerlist?name=${name}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteIdByNo(quoteNo) {
    const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${quoteNo}`)
    return result.data
  },
  async assertCustomerExists(name) {
    const url = `/ormmessages/load/customerexists?name=${encodeURIComponent(name)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getCustomerSummaries(fullname) {
    const url = `/customers/loadquote/customerlist?fullname=${fullname}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteSummaries(rego,claimno) {
    //const url = `/quotes/loadquote/quotelist?rego=${rego}&claimmo=${claimno}`
    const url = `/quotes/assignquote/${rego}/${claimno}`
    const result = await FqApi.get(url)
    return result.data
  }
}
