import { TableColumn } from '@/classes'

const InsurerColumns = [
  {
    title: 'Name',
    name: 'name',
    sortable: false,
    defaultOrder: 'asc',
    align: 'left'
  },
  {
    title: 'Repairer ID',
    name: 'repairerId',
    sortable: false,
    defaultOrder: 'asc',
    align: 'centered'
  },
  {
    title: 'Method',
    name: 'quotingMethod',
    sortable: false,
    defaultOrder: 'asc',
    align: 'centered'
  }
]

const CustomerColumns = [
  {
    title: 'Name',
    name: 'fullName',
    sortable: true,
    defaultOrder: 'asc',
    align: 'left'
  },
  {
    title: 'Address',
    name: 'streetName, suburb',
    sortable: true,
    defaultOrder: 'asc',
    align: 'left'
  }
]

const QuoteColumns = [
  new TableColumn('Quote No.', 'quoteNoRef', true, 'asc', 'left'),
  new TableColumn('Rego', 'regoNo', true, 'asc', 'left'),
  new TableColumn('Make', 'make', true, 'asc', 'left'),
  new TableColumn('Model', 'model', true, 'asc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'asc', 'left'),
  new TableColumn('Job Stage', 'jobStageText', true, 'asc', 'left')
]

export { InsurerColumns, CustomerColumns, QuoteColumns }
