<template>
  <div>
    <div class="field">
      <span>
        Vehicle already exists. Overwrite vehicle's information?
      </span>
    </div>
    <div class="field">
      <div class="control">
        <input 
          class="is-checkradio"
          id="yes"
          type="radio"
          name="yes"
          :value="true"
          v-model="innerValue.isOverwriteVehicle">
        <label for="yes">Yes</label>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <input 
          class="is-checkradio"
          id="no"
          type="radio"
          name="no"
          :value="false"
          v-model="innerValue.isOverwriteVehicle">
        <label for="no">No</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadQuoteVehicleStep',
  props: {
    value: null
  },
  data() {
    return {
      innerValue: this.value
    }
  },
  computed: {},
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {}
}
</script>
