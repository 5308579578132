module.exports.CustomerOptionTypes = {
  Create: 'create',
  Overwrite: 'overwrite',
  Reuse: 'reuse',
  KeepExisting: 'keepexisting'
}

// module.exports.Steps = {
//   CreateAssign: 1,
//   SelectInsurer: 2,
//   UpdateCustomer: 3,
//   OverwriteVehicle: 4,
//   GotoQuote: 5
// }

module.exports.Steps = {
  CreateAssign: { id: 1, enabled: true },
  SelectInsurer: { id: 2, enabled: true },
  UpdateCustomer: { id: 3, enabled: true },
  OverwriteVehicle: { id: 4, enabled: true },
  GotoQuote: { id: 5, enabled: true }
}
