<template>
  <div>
    <div v-if="value.customerOption === optionTypes.Create"
      class="field">
      <div v-if="!value.isPerson"
        class="field">
        <label class="label">Company Name</label>
        <div class="control">
          <input class="input"
            v-model="value.customerName"
            placeholder="Company Name"
            @input="assetExists">
        </div>
      </div>
      <div v-else
        class="field">
        <div class="field">
          <label class="label">Firstname</label>
          <div class="control">
            <input class="input"
              v-model="value.firstname"
              placeholder="Firstname"
              @input="assetExists">
          </div>
        </div>
        <div class="field">
          <label class="label">Lastname</label>
          <div class="control">
            <input class="input"
              v-model="value.lastname"
              placeholder="Lastname"
              @input="assetExists">
          </div>
        </div>
      </div>
      <div class="is-divider" />
    </div>
    <div class="field">
      <div class="control">
        <input class="is-checkradio"
          id="createnew"
          type="radio"
          name="createnew"
          :value="optionTypes.Create"
          v-model="value.customerOption">
        <label for="createnew">Create new customer</label>
      </div>
    </div>
    <!-- <div class="field">
      <div class="control">
        <input class="is-checkradio"
          id="overwrite"
          type="radio"
          name="overwrite"
          :value="optionTypes.Overwrite"
          v-model="value.customerOption"
          :disabled="!customerExists">
        <label for="overwrite">Overwrite customer</label>
      </div>
    </div> -->
    <div class="field">
      <div class="control">
        <input class="is-checkradio"
          id="reuse"
          type="radio"
          name="reuse"
          :value="optionTypes.Reuse"
          v-model="value.customerOption"
          :disabled="!customerExists">
        <label for="reuse">Use existing</label>
      </div>
    </div>
    <div v-if="!innerValue.isCreateQuote"
      class="field">
      <div class="control">
        <input class="is-checkradio"
          id="keepexisting"
          type="radio"
          name="keepexisting"
          :value="optionTypes.KeepExisting"
          v-model="value.customerOption"
          :disabled="!customerExists"
          @change="setCustomerId(optionTypes.KeepExisting)">
        <label for="keepexisting">Keep existing</label>
      </div>
    </div>
    <div v-if="value.customerOption === optionTypes.Reuse"
      class="field">
      <div class="is-divider" />
      <label class="label">Please select a customer</label>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        :show-pagination="false"
        @sort="sort">
        <tr v-for="(entity, index) in entitySummaries"
          :key="entity.messageId"
          :class="{ 'is-selected' : selectedRow === index }"
          @click="highlightSelected(index, $event)">
          <td>
            {{ entity.fullName }}
          </td>
          <td class="has-text-centered">
            {{ entity.streetName }}, {{ entity.suburb }}
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </div>
    <span class="has-text-danger"
      v-if="invalidCustomerId">
      <span class="icon has-text-danger">
        <i class="mdi mdi-alert-octagram mdi-24px" />
      </span>
      Please select a customer before continuing.
    </span>
    <!-- <pre>{{$v}}</pre> -->
  </div>
</template>

<script>
import { CustomerOptionTypes } from './LoadQuote'
import LoadQuoteService from './LoadQuoteService'
import _debounce from 'lodash.debounce'
import BulmaTable from '@/components/BulmaTable'
import { CustomerColumns } from './columns'

export default {
  name: 'LoadQuoteCustomerStep',
  inject: ['$vv'],
  components: {
    BulmaTable
  },
  props: {
    value: null
  },
  data() {
    return {
      entitySummaries: null,
      totalRows: 0,
      selectedRow: null,
      isTableLoading: false,
      customerExists: false,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 10
      },
      innerValue: this.value,
      existingCustomerId: this.value.customerId
    }
  },
  computed: {
    optionTypes() {
      return CustomerOptionTypes
    },
    columns() {
      return CustomerColumns
    },
    $v() {
      return this.$vv || this.$v
    },
    invalidCustomerId() {
      return this.$v.loadOptions.customerId.$error && this.innerValue.customerOption === CustomerOptionTypes.Reuse
    },
    fullname() {
      return `${this.innerValue.lastname}, ${this.innerValue.firstname}`
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    fullname: function (newVal, oldVal) {
      this.innerValue.customerName = newVal
    }
  },
  created() {
    this.assertCustomerExists(this.value.customerName)
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    sort() {},
    onPageChange() {},
    highlightSelected(index, event) {
      this.selectedRow = index
      this.innerValue.customerId = this.entitySummaries[index].customerId
    },
    async assertCustomerExists(name) {
      this.customerExists = await LoadQuoteService.assertCustomerExists(name)
    },
    assetExists: _debounce(async function () {
      this.assertCustomerExists(this.value.customerName)
    }, 500),
    async getEntitySummaries() {
      this.isTableLoading = true
      this.entitySummaries = await LoadQuoteService.getCustomerSummaries(this.value.customerName)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    setCustomerId(option) {
      if (option === CustomerOptionTypes.KeepExisting) {
        this.innerValue.customerId = this.innerValue.originalCustomerId
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
